import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/devops.jpg'
import person from '../../images/person.png'

const pageTitle = "Devops";
const pageSlug = "devops";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">DevOps can automate your software delivery to production, process with continuous integration, 
            deployments, and cloud techniques to give you a competitive advantage.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    To gain a competitive advantage and deliver value faster, DevOps can automate your software 
                    delivery to production, process.
                    </h3>
                    <p>A DevOps strategy that emphasizes collaboration, communication, and rapid feedback is the 
                        fastest and smartest way to create value. DevOps strategies work to automate the process of 
                        software delivery to production, by using techniques like automated testing, agile development, 
                        continuous integration, and continuous delivery.</p>
                    <p>By applying operations and development principles, tools, and techniques throughout your 
                        organization's entire delivery process you will see improved development frequency, release 
                        quality, team productivity, and architecture quality while realizing business value faster.</p>
                        <p>Are you ready to benefit from DevOps?</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                    <span className="break"></span>
                    <h4>Nuerex's DevOps solutions</h4>
                    <p>We analyze internal capabilities, available technology, and business needs first. 
                        We then tailor an operations and development strategy to meet the needs of your 
                        organization.</p>
                        <span className="break"></span>
                    <h4>DevOps Capabilities</h4>
                    <p>Implementing DevOps can take work and requires you to shift your focus; that is 
                        why our experts can help by providing the following:</p>
                    <ul>
                        <li>Transformation planning to ensure that benefits are realized in increments</li>
                        <li>Process design to ensure that this approach can be managed and measured throughout your organization</li>
                        <li>Readiness assessments to help determine your organizations existing levels of maturity 
                            and provide guidance on the value this approach can deliver</li>
                        <li>DevOps tool selection and implementation</li>
                        <li>Reliable testing services</li>
                        <li>Build and deployment automation</li>
                        <li>Architecture alignment to incorporate old models into the new development</li>
                        <li>Governance to ensure the longevity of the approach and providing processes and tools for the enterprise</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Related Capabilities</h4>
                    <h6>Our experts can also support your organization by performing the following:</h6>
                    <ul>
                        <li>Software Quality Assurance and Testing</li>
                        <li>Involves relevant capabilities that are required to move to continuous testing</li>
                        <li>Cloud Services</li>
                        <li>Makes delivering automation faster and easier</li>
                        <li>Agile Services</li>
                        <li>Ensure continuous improvements through transformations and coaching</li>
                        <li>Application Lifecycle Management Services</li>
                        <li>It gives long-term support to critical business applications</li>
                        <li>Change Management</li>
                        <li>Ensure DevOps will be adopted</li>
                    </ul>

                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>Want to learn how we can help build your DevOps organization? Or, want to work together to create value in your marketing team?</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us</Link>
                    </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
